import 'alpinejs';

window.productList = function (pageClass, subgroupCode, searchTerm) {
    const mobileBreakPoint = 1023;
    const initialOpen = window.innerWidth > mobileBreakPoint;
    const parent = window.carousel();
    return {
        initProductList() {
            this.buildGtmImpressions();
            this.sortAndFilterSliderInit();
            this.setupFilterScrollbar();
            this.filterScrollbarSetPosition();
        },
        subgroupCode,
        // Ordinamento
        // ------------------------------------
        highlightSidebar: false,
        sortOpen: false,

        // Visibilità Filtri
        // ------------------------------------
        width: window.innerWidth,
        breakpoint: mobileBreakPoint,
        open: initialOpen,
        isBeginning: false,
        isEnd: false,
        ariaHidden: (!initialOpen).toString(),
        resize() {
            this.width = window.innerWidth;
            this.open = this.width > this.breakpoint;
            this.ariaHidden = (!this.open).toString();
            this.setupFilterScrollbar()
        },
        scroll() {
            this.setupFilterScrollbar();
        },
        scrollFilters() {
            const filterNavEl = document.querySelector('.filters-nav');
            const filterScrollPosition = JSON.stringify({
                subgroupCode: subgroupCode,
                scrollPosition: filterNavEl.scrollTop
            });
            sessionStorage.setItem("filterScroll", filterScrollPosition);
        },
        filterScrollbarSetPosition() {
            setTimeout(() => {
                const filterNavEl = document.querySelector('.filters-nav');
                if (sessionStorage.getItem("filterScroll")) {
                    const filterScroll = JSON.parse(sessionStorage.getItem("filterScroll"));
                    if (filterNavEl && filterScroll.subgroupCode === subgroupCode) {
                        filterNavEl.scrollTop = filterScroll.scrollPosition;
                    }
                }
            }, 80);
        },
        setupFilterScrollbar() {
            const filterNavEl = document.querySelector('.filters-nav');
            if (!filterNavEl) return;
            if (window.innerWidth > mobileBreakPoint) {
                let filterNavHeight = window.innerHeight;
                if (filterNavEl.getBoundingClientRect().top !== 0) {
                    let filterNavDistanceFromTop = filterNavEl.getBoundingClientRect().top;
                    if (filterNavEl.getBoundingClientRect().top < 0)
                        filterNavDistanceFromTop = 0;
                    filterNavHeight = filterNavHeight - filterNavDistanceFromTop;
                }
                filterNavEl.style.height = filterNavHeight + 'px';
                filterNavEl.style.overflow = 'auto';
                filterNavEl.style.overscrollBehavior = 'contain';
            } else {
                filterNavEl.style.height = 'initial';
                filterNavEl.style.overflow = 'initial';
                filterNavEl.style.overscrollBehavior = 'initial';
            }
        },
        filterPressed() {
            return this.width <= mobileBreakPoint && this.open;
        },
        filterHidden() {
            return this.width <= mobileBreakPoint;
        },
        openMobileFilters() {
            document.body.style.overflow = 'hidden';
            this.open = this.width > mobileBreakPoint || !this.open;
            this.ariaHidden = (!this.open).toString();
        },
        closeOnMobile() {
            if (window.innerWidth < mobileBreakPoint) {
                document.body.style.overflow = 'auto';
                this.open = false;
            }
        },

        // TAB
        // ------------------------------------
        usedTab: false,
        tab() {
            this.usedTab = true;
        },

        // GTM
        // ------------------------------------
        buildGtmImpressions() {
            const products = document.querySelectorAll("div.prod-item-preview.product-item");
            const previewTexts = document.querySelectorAll("div.prod-item-preview.product-item div.preview-text");
            let index = 0;
            let position = 1;
            const impressions = Array.from(products).map(elem => {
                const previewText = previewTexts[index++].innerHTML;
                return {
                    id: elem.dataset.code,
                    name: previewText,
                    list: "Catalog Search",
                    position: position++
                };
            });
            gtmUtils.productsImpression(impressions)
        },

        // Animazione Show/Hide Filtri
        // ------------------------------------
        showHideFiltersAnimation: {
            ['enter-active-class']: 'transition-opacity ease-linear duration-300',
            ['enter-class']: 'opacity-0',
            ['enter-to-class']: 'opacity-100',
            ['leave-active-class']: 'transition-opacity ease-linear duration-300',
            ['leave-class']: 'opacity-100',
            ['leave-to-class']: 'opacity-0',
        },
        sortAndFilterSliderInit() {
            this.initSlider();
        },
        initSlider: parent.init,
        ...parent,
    };
}

window.userFavList = function () {
    return {
        navigateBack() {
            if (utils.isValidInternalReferrer())
                history.back();
            else
                document.location = '/';
        },
    };
}

window.outletProductList = function () {
    return {
        navigateBack() {
            if (utils.isValidInternalReferrer())
                history.back();
            else
                document.location = '/';
        },
    };
}

// Alpine Filters Data
window.filtersData = function (multipleValueFilterEnabled) {
    return {
        showMoreList: [],
        subgroupCode: '',
        initFilters($el, collapseAttribute) {
            this.setupFilters($el, collapseAttribute);
            if (document.querySelector('.subgroup-container'))
                this.subgroupCode = document.querySelector('.subgroup-container').dataset.subgroup;
            this.setupShowMore();
        },
        setupShowMore() {
            if (sessionStorage.getItem("showMoreData")) {
                const showMoreData = JSON.parse(sessionStorage.getItem("showMoreData"));
                if (this.subgroupCode === showMoreData.subgroupCode) {
                    this.showMoreList = JSON.parse(sessionStorage.getItem("showMoreData")).showMoreList;
                } else {
                    sessionStorage.removeItem("showMoreData");
                }
            }
            setTimeout(() => {
                for (const attributeSlug of this.showMoreList) {
                    const showMore = document.querySelector(`.show-more[data-id="${attributeSlug}"]`);
                    if (showMore) {
                        this.showHiddenFilters({target: showMore});
                    }
                }
            }, 35);
        },
        setupFilters($el, collapseAttribute) {
            $el.querySelector('.filter-values-container').classList.remove('h-24');

            if (collapseAttribute) {
                this.collapseAttributes($el)
            } else {
                for (const filterCont of $el.querySelectorAll('.filter-values-container .filter-values')) {
                    if (filterCont)
                        filterCont.classList.remove('hidden');
                }
            }
        },
        collapseAttributes($el) {
            for (const filterCont of $el.querySelectorAll('.filter-values-container .filter-values')) {
                const filters = filterCont.children;
                if (filters && filters.length > 3) {
                    for (let i = 3; i < filters.length; i++) {
                        filters[i].classList.add('hidden-impo');
                    }

                    if (filterCont.parentElement.querySelector('.show-more')) {
                        const showMore = filterCont.parentElement.querySelector('.show-more');
                        showMore.classList.remove('hidden');
                    }
                }
                if (filterCont)
                    filterCont.classList.remove('hidden');
            }
        },
        showMore($event) {
            this.showHiddenFilters($event);
            if (this.subgroupCode !== '')
                this.saveShowMoreState($event);
        },
        showHiddenFilters($event) {
            for (let i = 3; i < $event.target.parentElement.querySelectorAll('.filter').length; i++) {
                const filter = $event.target.parentElement.querySelectorAll('.filter')[i];
                if (filter.classList.contains('hidden-impo')) {
                    filter.classList.remove('hidden-impo');
                    $event.target.classList.add('hidden');
                }
            }
        },
        saveShowMoreState($el) {
            const showMoreJson = {
                subgroupCode: this.subgroupCode,
                showMoreList: this.showMoreList
            };
            const attributeSlug = $el.target.dataset.id;
            showMoreJson.showMoreList.push(attributeSlug);

            sessionStorage.setItem("showMoreData", JSON.stringify(showMoreJson));
        },
        open: true,
        openCloseFilterAnimation: {
            ['x-transition:enter']: 'transition ease-out duration-100',
            ['x-transition:enter-start']: 'transform opacity-0 scale-95',
            ['x-transition:leave']: 'transition ease-in duration-75',
            ['x-transition:leave-end']: 'transform opacity-0 scale-95'
        },
        toggleView() {
            this.open = !this.open;
            utils.resetTextSelection();
        },
        disabledCheckboxClick($dispatch, $event, attributeName, value, attributeSlug, valueSlug) {
            $event.target.checked = false;

            const url = new URL(document.location.href);
            url.search = '?f-' + attributeSlug + '=' + valueSlug;
            const html = "" +
                "Il filtro selezionato non contiene prodotti con \"" + attributeName + "\" \"" + value + "\". " +
                "Puoi eseguire una <a class='font-bold underline text-Main-Accent' href=\"" + url.toString() + "\">nuova ricerca a partire da " + value + "</a>.";

            const box = $event.target.getBoundingClientRect();

            $dispatch('filter-no-results', {
                html, box
            });
        },
        checkboxClick($event, attrSlug, value, valueId) {
            const url = new URL(document.location.href);
            const paramName = 'f-' + attrSlug;
            let attributeValues = url.searchParams.get(paramName) || '';

            if (multipleValueFilterEnabled) {
                // Filtri Unione
                // ------------------------------------
                if ($event.target.checked) {
                    attributeValues = (attributeValues.length > 0 ? attributeValues + '_' : '') + value;
                    url.searchParams.set(paramName, attributeValues)

                    fetch(`/ajaxContents?type=filter-click&value_id=${valueId}`)
                        .catch(utils.errorLog);
                } else {
                    attributeValues = attributeValues.split('_').filter(v => v !== value).join('_');
                    if (attributeValues.length > 0) {
                        url.searchParams.set(paramName, attributeValues);
                    } else {
                        url.searchParams.delete(paramName);
                    }
                }
                // ------------------------------------
            } else {
                // Filtri Singola Selezione
                // ------------------------------------
                if ($event.target.checked) {
                    url.searchParams.set(paramName, value)
                } else {
                    url.searchParams.delete(paramName);
                }
                // ------------------------------------
            }

            url.searchParams.delete('pag');
            document.location.href = url.toString();
        }
    }
}
