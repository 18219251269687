window.CartApi = {
    updateProductIntoCart(productCode, quantityToSet, $dispatch) {
        fetch('/api21/cart/set-product-into-cart?product_code=' + productCode + '&quantity=' + quantityToSet)
            .then(res => res.json())
            .then(res => {
                const modalName = res.additionalData.modalName;

                if (res.success) {
                    if (modalName) {
                        window.modal.openDefinedModal($dispatch, modalName, res.additionalData)
                            .then(() => $dispatch('cart-update', res));

                    } else {
                        $dispatch('cart-update', res);
                    }

                } else {
                    if (modalName)
                        window.modal.openDefinedModal($dispatch, modalName, res.additionalData);
                    else
                        alert("Errore interno...");
                }
            })
            .catch(utils.errorCatch);
    },

    removeProductFromCart(productCode, $dispatch) {
        fetch('/api21/cart/remove-product-from-cart?product_code=' + productCode)
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    $dispatch('cart-update', res);

                } else {
                    if (res.additionalData.modalName)
                        window.modal.openDefinedModal($dispatch, res.additionalData.modalName, res.additionalData)
                    else
                        alert("Errore interno...");
                }
            })
            .catch(utils.errorCatch);
    },

    setDeliveryToFloor({toggle, elevator}, $dispatch) {
        fetch('/api21/cart/set-delivery-to-floor?delivery_to_floor=' + toggle + '&elevator=' + elevator)
            .then(res => res.json())
            .then(res => {
                const modalName = res.additionalData.modalName;
                if (modalName)
                    window.modal.openDefinedModal($dispatch, modalName, res.additionalData)
                if (res.success)
                    $dispatch('cart-update', res);
            })
            .catch(utils.errorCatch);
    },

    saveCart(cartName, $dispatch) {
        fetchPost('/api21/cart/save-cart', {
            cart_name: cartName
        })
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    document.location = '/view_ordini_sospesi.jsp';

                } else {
                    if (res.additionalData && res.additionalData.modalName)
                        window.modal.openDefinedModal($dispatch, res.additionalData.modalName, res.additionalData)
                    else
                        alert("Errore interno...");
                }
            })
            .catch(utils.errorCatch);
    },

    resetUnavailableProducts($dispatch) {
        fetch('/api21/cart/reset-unavailable-products')
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    if (res.additionalData.modalName)
                        window.modal.openDefinedModal($dispatch, res.additionalData.modalName, res.additionalData)
                    else
                        alert("Errore interno...");
                }
            })
            .catch(utils.errorCatch);
    },

    pallet: {
        async setProvince(provinceCode, $dispatch) {
            fetch('/api21/cart/set-pallet-province?province_code=' + provinceCode)
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        $dispatch('cart-update', res);

                    } else {
                        if (res.additionalData.modalName)
                            window.modal.openDefinedModal($dispatch, res.additionalData.modalName, res.additionalData)
                        else
                            alert("Errore interno...");
                    }
                })
                .catch(utils.errorCatch);
        }
    },
    savedCart: {
        async restore(savedCartDeprecatedId, method, $dispatch) {
            let conferma = method === 'add' ? 'Aggiungere' : 'Sostituire';
            await fetch('/controllerCarrelloSospeso?azione=ripristina&conferma=' + conferma + '&fkCarrello=' + savedCartDeprecatedId)
                .catch(utils.errorCatch);
        },
        async delete(savedCartDeprecatedId, $dispatch) {
            await fetch('/controllerCarrelloSospeso?azione=elimina&conferma=y&fkCarrello=' + savedCartDeprecatedId)
                .catch(utils.errorCatch);
        }
    },
    quote: {
        setDeliveryToFloor(quoteDeprecatedId, {toggle, elevator}, $dispatch) {
            fetch('/api21/quote/set-delivery-to-floor?quote_deprecated_id=' + quoteDeprecatedId + '&delivery_to_floor=' + toggle + '&elevator=' + elevator)
                .then(res => res.json())
                .then(res => {
                    const modalName = res.additionalData.modalName;
                    if (modalName)
                        window.modal.openDefinedModal($dispatch, modalName, res.additionalData)
                    if (res.success)
                        $dispatch('quote-update', res);
                })
                .catch(utils.errorCatch);
        },
    },
    order: {
        async abortOrder(orderDeprecatedId, $dispatch) {
            await fetch('/controllerOrdine?idOrdine=' + orderDeprecatedId + '&azione=elimina')
                .catch(utils.errorCatch);
        },
        async buyAgain(orderDeprecatedId, method, $dispatch) {
            let conferma = method === 'add' ? 'add' : 'replace';
            await fetch('/ripristinaOrdine?confirm=' + conferma + '&idOrdine=' + orderDeprecatedId)
                .catch(utils.errorCatch);
        }
    }
}
